<template>
  <footer class="app-footer" role="contentinfo">
    <p class="app-footer__copyright">
      <span>&copy; 2013 - {{ currentYear }}</span>
      <span><a href="https://www.luckystarry.com" target="_blank" class="hover-link">www.luckystarry.com</a></span>
      <span>版权所有</span>
      <span class="app-footer__icp">
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="external nofollow noopener" class="hover-link">苏ICP备13050888号</a>
      </span>
    </p>
    <p class="app-footer__license">
      <em>
        除非另有说明，本站所有内容均采用
        <a class="hover-link" href="https://creativecommons.org/licenses/by-nc/4.0/" title="署名-非商业性使用 (by-nc)" target="_blank" rel="external nofollow noopener">
          <span>署名-非商业性使用 (by-nc)</span>
          <i class="fab fa-creative-commons-by" aria-hidden="true"></i>
          <i class="fab fa-creative-commons-nc" aria-hidden="true"></i>
        </a>
        许可协议
      </em>
    </p>
    <p class="app-footer__upyun">
      <a href="https://www.upyun.com/?utm_source=lianmeng&utm_medium=referral" target="_blank" rel="external nofollow noopener" class="hover-link">
        感谢
        <img class="app-footer__upyun-logo" alt="又拍云" src="//cdn.luckystarry.com/brands/icon/upyun.svg" />
        提供免费内容加速服务
      </a>
    </p>
  </footer>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const currentYear = computed(() => new Date().getFullYear())
</script>

<style lang="less">
.app-footer {
  padding: @spacing-lg @spacing-base;
  background: @gray-9;
  color: fade(@text-color-inverse, 65%);
  text-align: center;
  font-size: @font-size-sm;
  line-height: @line-height-base;

  p {
    margin: @spacing-sm 0;
  }

  // 统一所有文本样式
  span,
  a,
  em {
    color: fade(@text-color-inverse, 65%);
    font-size: @font-size-sm;
    text-decoration: none;
    font-style: normal;
    transition: color @transition-duration @transition-timing;
  }

  a:hover {
    color: @text-color-inverse;
  }

  &__copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: @spacing-sm;
  }

  &__license {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: @spacing-xs;

    i {
      margin: 0 @spacing-xs;
    }
  }

  &__upyun {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: @spacing-xs;

    &-logo {
      height: 20px;
      width: auto;
      vertical-align: text-bottom;
      margin: 0 @spacing-xs;
    }
  }

  @media (max-width: @screen-sm) {
    .app-footer__icp {
      display: block;
      margin-top: @spacing-sm;
    }
    .app-footer__copyright {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .hover-link {
    color: fade(@text-color-inverse, 65%);
    text-decoration: none;
    position: relative;
    padding-bottom: 2px;
    transition: color @transition-duration @transition-timing;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: @text-color-inverse;
      transform: scaleX(0);
      transition: transform @transition-duration @transition-timing;
      transform-origin: right;
    }

    &:hover {
      color: @text-color-inverse;

      &::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
}
</style>

<template>
  <header class="app-header">
    <div class="app-header__wrapper">
      <!-- Logo -->
      <div class="app-header__logo">
        <router-link to="/" class="app-header__logo-link" title="返回首页">
          <img src="//cdn.luckystarry.com/assets/logo.png" alt="星空Online Logo" class="app-header__logo-image" />
          <img src="//cdn.luckystarry.com/assets/logo-text.png" alt="星空Online" class="app-header__logo-text" />
        </router-link>
      </div>

      <!-- 搜索框 -->
      <div class="app-header__search">
        <form class="app-header__search-form" @submit.prevent="handleSearch">
          <i class="fas fa-search app-header__search-icon" aria-hidden="true"></i>
          <input type="text" v-model="searchTerm" placeholder="搜索文章..." autocomplete="off" class="app-header__search-input" aria-label="搜索文章" @focus="isFocused = true" @blur="isFocused = false" />
        </form>
      </div>

      <!-- 主导航 -->
      <nav class="app-header__nav" role="navigation">
        <ul class="app-header__nav-list">
          <li class="app-header__nav-item" :class="{ active: currentRoute === 'home' }">
            <router-link to="/">博客</router-link>
          </li>
          <li class="app-header__nav-item" :class="{ active: currentRoute === 'about' }">
            <router-link to="/about">关于</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const searchTerm = ref('')
const isFocused = ref(false)

const currentRoute = computed(() => route.name)

const handleSearch = () => {
  router.push({
    path: '/',
    query: searchTerm.value.trim() ? { title: searchTerm.value.trim() } : {}
  })
}
</script>

<style lang="less">
.app-header {
  background: @theme-primary;
  box-shadow: @box-shadow-base;

  &__wrapper {
    max-width: @container-width;
    margin: 0 auto;
    padding: 0 @spacing-lg;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: @header-height;
  }

  &__logo {
    height: @header-height;
    padding: @spacing-sm 0;
    display: flex;
    align-items: center;

    &-link {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    &-image {
      height: 40px;
      width: auto;
      margin-right: @spacing-sm;

      @media (max-width: @screen-md) {
        height: 35px;
      }
    }

    &-text {
      height: 30px;
      width: auto;

      @media (max-width: @screen-md) {
        height: 25px;
      }
    }
  }

  &__search {
    flex: 0 1 400px;
    margin: 0 @spacing-lg;

    &-form {
      position: relative;
    }

    &-input {
      width: 100%;
      height: 36px;
      padding: @spacing-sm @spacing-xl;
      border: 1px solid @border-color-base;
      border-radius: @border-radius-xl;
      font-size: @font-size-sm;
      transition: all @transition-duration @transition-timing;
      box-sizing: border-box;

      &:focus {
        border-color: @theme-primary;
        box-shadow: 0 0 0 2px fade(@theme-primary, 20%);
        outline: none;
      }
    }

    &-icon {
      position: absolute;
      left: @spacing-sm;
      top: 50%;
      transform: translateY(-50%);
      color: @text-color-third;
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    &-list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &-item {
      margin: 0 @spacing-sm;

      a {
        color: @text-color-inverse;
        text-decoration: none;
        font-size: @font-size-base;
        padding: @spacing-sm @spacing-base;
        border-radius: @border-radius-base;
        transition: all @transition-duration @transition-timing;

        &:hover {
          color: @text-color-inverse;
          background: fade(@gray-1, 10%);
        }
      }

      &.active a {
        color: @text-color-inverse;
        background: fade(@gray-1, 20%);
      }
    }
  }
}

@media (max-width: @screen-md) {
  .app-header {
    &__wrapper {
      padding: 0 @spacing-sm;
    }

    &__search {
      flex: 0 1 200px;
      margin: 0 @spacing-sm;
    }

    &__nav {
      display: none;

      &-item {
        margin: 0 @spacing-xs;

        a {
          padding: @spacing-xs @spacing-sm;
          font-size: @font-size-sm;
        }
      }
    }
  }
}

.app-header__search-input {
  transition: width 0.3s;
  width: 200px;
}

.app-header__search-input:focus {
  width: 300px;
}
</style>

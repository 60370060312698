import { createApp } from './main'
import './styles/style.less'

const { app, router, pinia } = createApp()

// 在挂载应用之前恢复状态
if (window.__INITIAL_STATE__) {
  if (typeof window.__INITIAL_STATE__ === 'string') {
    pinia.state.value = JSON.parse(window.__INITIAL_STATE__)
  } else {
    pinia.state.value = window.__INITIAL_STATE__
  }
}

if (import.meta.env.PROD) {
  app.config.errorHandler = (err, instance, info) => {
    console.error('Vue Error:', err)
    console.error('Component:', instance)
    console.error('Info:', info)
  }

  app.config.performance = true

  app.config.warnHandler = (msg, instance, trace) => {
    console.warn('[Vue warn]:', msg)
    console.warn('Component trace:', trace)
  }
}

// 添加全局未捕获错误处理
window.addEventListener('error', (event) => {
  console.error('Global error:', event.error)
})

// 添加未处理的 Promise 拒绝处理
window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled Promise rejection:', event.reason)
})

router
  .isReady()
  .then(() => {
    app.mount('#app')
  })
  // @ts-ignore
  .catch((error) => {
    console.error('Router error:', error)
  })

import { createPinia } from 'pinia'
import { createSSRApp } from 'vue'
import App from './App.vue'
import { createRouter } from './router'
import { createHead } from '@vueuse/head'

export function createApp() {
  const app = createSSRApp(App)
  const router = createRouter()
  const pinia = createPinia()
  const head = createHead()

  app.use(router)
  app.use(pinia)
  app.use(head)

  // 添加环境检测
  if (import.meta.env.PROD) {
    console.log('[App] Running in production mode')
  } else {
    console.log('[App] Running in development mode')
  }

  return { app, router, pinia }
}
